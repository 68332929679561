.link-menu {
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 100;
    height: 100%;
    width: 100%;
}

.title-menu {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark);
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    padding-right: 5px;
}

.main-link-title {
    padding-left: 18px;
}

.sub-link-title {
    padding-left: 34px;
}

.sub-link {
    width: calc(100% - 30px) !important;
}

.bold-text {
    font-weight: 600;
}
