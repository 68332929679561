.home-header-container {
    height: 48px;
    padding-top: 14px;
}

.home-header-image {
    padding-left: 14px;
}

.home-header-text{
    font-weight:600;
    font-size:16px;
    padding-top:14px;
    padding-left:18px;
}

.home-body-container {
        background-image: url('../../Images/Home/HomeBackground.jpg');
        background-size: cover;
        height: 100vh;
        width:auto;
}