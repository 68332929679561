.first-item-delete > div {
    justify-content: normal;
    padding-left: 1px;
}

.delete-table-body-description > div {
    font-family: Segoe UI;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    text-transform: uppercase;
}

.table-body-description > div {
    color: var(--dark);
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    text-transform: uppercase;
}
.omniinfolabel-container-top > span > input {
    margin-left: 20px;
}
.omniinfolabel-container-top > span > div {
    margin-left: 20px;
}
.add-buttonRow {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.check-Row {
    width: 140px;
}
.pivot-center-client > div[role="tablist"] {
    padding-left: 24px;
}
.pivot-center-client>div>button {
    padding-left: 8px !important;
    margin-right:32px !important;
}
.tooltip-delete {
    background-color: var(--primary);
}
.card-scroll {
    overflow-y: auto;
    height: 75vh;
}
.mapsLink {
    color: var(--primary);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-align: end;
    line-height: normal;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
    text-decoration: none;
}
.item-table-selected {
    background-color: var(--primary);
    cursor: pointer;
}
    .item-table-selected > td > div > div {
        color: #fff;
    }
    .item-table-selected:hover {
        background-color: var(--primary) !important;
        cursor: pointer;
    }

        .item-table-selected:hover td,
        .item-table-selected:hover span {
            color: #fff !important;
        }
        .item-table-selected td,
        .item-table-selected span {
            fill: #fff;
        }
.item-table:hover {
    background-color: var(--primary) !important;
    cursor: pointer;
}
    .item-table:hover td,
    .item-table:hover span {
        color: #fff !important;
        fill: #fff;
    }

.card-omni-contact {
    width: 440px;
    max-width: 100%;
    height: fit-content;
    padding-left: 40px !important;
    padding-right: 20px !important;
    padding-top: 14px !important;
    padding-bottom: 30px !important;
    margin: 14px 7px 14px 14px;
}
.card-title-danger {
    color: var(--danger) !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.card-omni-large {
    width: 890px;
    max-width: 100%;
    height: fit-content;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 14px !important;
    padding-bottom: 30px !important;
    margin: 14px 7px 14px 14px;
}
.buttonLocation {
    color: var(--primary) !important;
}
.rowButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.iconList {
    fill: var(--primary);
}
.marginWorld {
    margin-left: 26px;
}

.ms-Pivot-link[data-is-overflowing="true"] ~ .overflowMenuButton-210 {
    visibility: visible;
    position: absolute;
    margin-right: 0px !important;
}
.ms-Pivot-link[data-is-overflowing="true"] ~ .overflowMenuButton-212 {
    visibility: visible;
    position: absolute;
    margin-right: 0px !important;
}
.callOutButtonLeft:hover {
    background-color: var(--danger) !important;
    color: white !important;
}

.callOutButtonRight:hover {
    background-color: var(--secondary) !important;
    color: white !important;
}
.label-info {
    color: var(--info) !important;
}
    .label-info:hover {
        color: white !important;
    }
.color-danger {
    color: var(--danger);
}