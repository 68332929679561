.header-container{
    background: var(--dark);
    color: #fff;
    height:48px;
    padding-left:9px;
    padding-right:10px;
}
.header-text{
    color:var(--white);
    font-weight:600;
}
/*      custom speed test component style       */
.speed-test-widget{
    margin-right:25px;
}