.dropdown-omni {
    background-color: var(--light-hover) !important;
    width: 100% ;
    min-width: initial !important;
}
.dropdown-omni-validation {
    background-color: var(--light-hover) !important;
    width: 100%;
    min-width: initial !important;
    border:1px solid var(--primary) !important;

}
    .dropdown-omni-validation + div {
        color: var(--primary) !important;
    }

        .dropdown-omni-validation + div > span {
            color: var(--primary) !important;
        }
    .dropdown-omni:after {
        background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
        border-bottom: 2px solid var(--info) var(--primary) !important;
    }
.dropdown-omni-validation:after {
    background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
    border-bottom: 2px solid var(--info) var(--primary) !important;
}
.list-dropdown-omni {
    max-height: 350px !important;
}
    .list-dropdown-omni div:hover {
        background-color: var(--primary) !important;
        color: white !important;
    }
    .list-dropdown-omni div[aria-selected="true"] {
        background-color: var(--primary) !important;
        color: white !important;
        fill: white;
    }
.list-dropdown-omni > div > svg {
    margin-right: 12px;
}
/*.dropdown-omni-small {
    min-width: initial !important;
    width: 50%;
}*/
@media (max-width:992px) {
    .dropdown-omni {
        width: auto;
    }
    .dropdown-omni-validation {
        width: auto;
    }
}
