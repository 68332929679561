/*body {
    background-image: url('../../Images/Authentication/LoginBackground.png');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    font-size: 15px;
    font-weight: 400;
}
*/
.login-header {
    background-color: #333;
    height: 48px;
    color: #fff;
}
.login-header-logo {
    width:32px;
    height:32px;
}

.login-header-icon {
    width: 30px;
    height: 30px;
}
.login-header-label {
    margin-left:10px;
}
/*      ------------------      Test Speed          --------------------*/
.speed-test-container {
    margin-right: 355px; /* EQUALS TO: login-box-outer   + 10*/
}
.downloadSpeed,
.uploadSpeed {
    fill: #fff;
}

.downloadSpeedSlow,
.uploadSpeedSlow {
    fill: #FF6600;
}

.downloadSpeedNormal,
.uploadSpeedNormal {
    fill: #0F7AFF;
}

.downloadSpeedOptimal,
.uploadSpeedOptimal {
    fill: #fff;
}

.downloadSpeedText{
    margin-left:10px;
}
.downloadSpeedTextSlow {
    color: #FF6600;
}

.downloadSpeedTextNormal {
    color: #0F7AFF;
}

.downloadSpeedTextOptimal {
    color: #fff;
}

.mainStackContainer {
    background-image: url('../../Images/Authentication/LoginBackground.png');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    font-size: 15px;
    font-weight: 400;
}
/*      ------------------      CARD customer info          --------------------*/
.card {
    max-width: 550px;
    padding: 0px !important;
    margin-left: 40px;
    margin-top: 40px;
    border: none;
}
.space-row-card{
    margin-top:10px;
}
.card-header {
    background-color: #0F7AFF;
    padding: 16px;
    width: 396px;
    max-width: 396px;
    color: #fff;
    border-radius: 0 !important;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
    .card-header .card-icon-title {
        margin-right:18px;
    }
    .card-header .card-icon-animated {
        display:inline-block;
        width:20px;
        height:20px;

    }
    .card-header .card-header-title {
        font-family: Segoe UI;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .card-header .value-card-text {
        margin-left:10px;
    }

    .card-header .card-icon-subtitle {
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
    }

    .card-preview {
        max-width: 120px;
    }



    .speed-spiner{
        display:inline-block !important;
        padding-left:7px;
    }

    @media (max-width: 992px){
        .card-header {
            width: calc(100% - 145px);
        }
        .card-customer{
            width: calc(100% - 70px);
        }
    }
    @media (max-width: 768px){
        .customer-stack{
            display: none;
        }
        .box-stack{
            padding-left: calc(100vw - 75%);
        }
    }
    @media (max-height:600px){
        .login-box-fields-container{
            padding:  10px 0 0 0;
        }
        .login-header{
            position: fixed;
            z-index: 1;
            width: 100%;
        }
        .box-stack{
            margin-top: 48px
        }
        .customer-stack{
            margin-top: 48px;
        }
    }



    

    /*
    @media (max-width: 992px){
        .customer-stack{
            display: none;
        }
        .box-stack{
            padding-left: 50vw;
        }
    }
    */