
.items-paginate {
    height: 40px;
    border-top: 1px solid var(--light);
    padding-right: 20px;
    background-color: var(--light-hover);
    padding-left: 20px;
}

.items-paginate-text {
    color: var(--secondary);
}

.items-paginate-left {
    fill: var(--secondary) !important;
    margin-left: 10px;
    min-height: 20px;
}

    .items-paginate-left:hover {
        fill: var(--info) !important;
        cursor: pointer;
    }

.items-paginate-right {
    fill: var(--secondary) !important;
    margin-left: 10px;
}

    .items-paginate-right:hover {
        fill: var(--info) !important;
        cursor: pointer;
    }
.container-table-paginate {
    overflow-y: auto;
    height: calc(100vh - 180px);
    overflow-x:auto;
}
.search-icon {
    fill: var(--info);
}
.search-icon-value {
    fill: var(--orange);
    color: var(--orange) !important;
}
.clean-value-icon {
    fill: var(--secondary);
}

.icon-search-left {
    fill: var(--secondary) !important;
    margin-left: 10px;
    min-height: 20px;
}

    .icon-search-left:hover {
        fill: var(--orange) !important;
        cursor: pointer;
    }

.icon-search-right {
    fill: var(--secondary) !important;
    margin-left: 10px;
}

    .icon-search-right:hover {
        fill: var(--orange) !important;
        cursor: pointer;
    }
.item-found {
    background-color: var(--orange);
}
    .item-found:hover {
        background-color: var(--orange) !important;
    }
.item-found > td {
    color: white;
}
    .item-found > td :hover {
        color: white !important;
    }
    .item-found > td > div > div > span > svg {
        fill: white;
    }