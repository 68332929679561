.card-omni {
    width: 440px;
    max-width: 100%;
    height: fit-content;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 14px !important;
    padding-bottom: 30px !important;
    margin: 14px 7px 14px 14px;
}
.card-omni-without-width {
    max-width: 100%;
    height: fit-content;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 14px !important;
    padding-bottom: 30px !important;
    margin: 14px 7px 14px 14px;
}

@media (max-width: 992px){
    .card-omni{
        width: auto;
        max-width: 440px;
    }
}

.card-container-title {
   padding-bottom:6px;
}
.card-title {
    color: var(--dark) !important;
    font-size: 18px !important;
    font-weight: 600 !important; 
}






/* Contenedor card */
.container-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 92px);
}
.container-scroll-two-bars {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 136px);
}
.container-scroll-three-bars {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 182px);
}
.card-items-container{
    max-height: 350px;
    overflow: auto;
}