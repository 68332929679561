.login-box-outer {
    height: calc(100vh - 48px);
    color: #fff;
    background: rgba(0, 0, 0, 0.38);
    width: 345px;
    max-width: 345px;
}
.login-box-fields-container {
    padding: 80px 0px 0px;
}

.stack-item {
    padding: 35px 52px 0;
    width: 100%;
}

.stack-item-bottom {
    padding-bottom: 35px;
}

.stack-item-buttons {
    padding-left: 48px;
    padding-right: 48px;
    text-align:center;
}

.lbl {
    color: #fff;
    font-weight: 400;
    font-size: 15px;
}

.lbl-validation-error-container,
.caps-lock-container {
    padding: 0 52px;
}
.lbl-caps-lock {
    color: #FF6600;
    font-size: 15px;
    font-weight: 400;
    padding-top:15px;
}

.lbl-validation-errors {
    padding-top: 15px;
    color: red;
}

.btn {
    border-radius: 0;
    width: 120px;
    border: 1px solid #fff;
    
}

    .btn span {
        font-weight: 400 !important;
        font-size: 15px;
    }

    .btn:hover {
        border: 2px solid #fff;
    }

.btn-dark {
    background-color: #333333;
}

.btn-primary {
    background-color: #0F7AFF;
}

/*  --------------------        tile-button         -------------------*/
.tile-button {
    width: 100%;
    padding: 12px 12px 12px 7px !important;
    justify-content: space-between !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    max-height: 48px;
    border: none !important;
}

.tile-button-blue {
    background: #0079D8 !important;
}
.tile-button-blue:hover {
    background: #0079d8ec !important;
}
.tile-button-red {
    background: #E81123 !important;
}
.tile-button-red:hover {
    background: #e81123e4 !important;
}
    .tile-button > span:first-child {
        color: #fff;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .tile-button > span:nth-child(2) {
        width:auto;
        height:auto;
    }


    
    @media (max-height:600px){
        .login-box-outer{
            min-height: 600px;
        }
        .login-box-fields-container{
            padding:  10px 0 0 0;
        }
    }

/*      fingerprint panel           */
.fingerprint-login-panel > div.ms-Panel-main {
    padding: 18px;
}

.fingerprint-login-panel div.ms-Panel-headerText {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.login-fingerprint-notification {
    background-color: #FFF4CE;
    
}
    .login-fingerprint-notification span.ms-MessageBar-innerText {
        font-size: 10px !important;
    }


    .login-fingerprint-message {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 12px;
        font-weight: 600;
    }
.fingerprint-login-panel-overlay {
    background-color: rgba(0, 0, 0, 0.38);
}
            /*   card   */
.card-tile {
    background-color: #F2F2F2 !important;
    border: none !important;
    padding: 20px !important
}
    .card-tile::after {
        border: none !important;
    }
.card-tile-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.card-tile-progress-bar {
    background-color: #DADADA !important;
    margin-top: 14px;
    margin-bottom: 14px;
    height: 6px;
}

.card-tile-progress-bar-danger > div {
    background-color: #E81123 !important;
}
.card-tile-progress-bar-info > div {
    background-color: var(--info) !important;
}
.card-tile-progress-bar-primary > div {
    background-color: #0079D8 !important;
}

    .card-tile-progress-bar-description {
        font-size: 15px;
        font-weight: 400;
        color: #898989;
    }
    .card-tile-progress-bar-description > .countdown {
        float:right;
    }

.card-tile-hspace {
    margin-top: 46px;
}
    /*   end card   */
.fingerprint-login-panel .tile-button {
    justify-content: space-between !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    width: 124px;
    border: none !important;
    background-color: #898989;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    font-size: 12px;
    font-weight: 400;
}
    .fingerprint-login-panel .tile-button:hover {
        background: #898989d6 !important;
    }
    .fingerprint-login-panel .tile-button > span:first-child {
        padding-top: 20px;
    }