.catalog-font {
    font-weight: 600 !important;
}

.catalog-border {
    border-bottom: 1px #e0e0e0 solid;
    height: 44px;
    padding-bottom: 0px;
    padding-top: 0px
}

    .catalog-border > div {
        /*padding-right: 10px;*/
    }

.catalog {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

    .catalog:hover {
        background-color: #F2F2F2 !important;
        cursor:pointer;
    }

.padding-firstlevel {
    padding-left: 30px;
}

.padding-secondlevel {
    padding-left: 60px;
}

.selected {
    background-color: #F2F2F2 !important;
}


.permits-card{
    height: calc(100vh - 230px);
    display: block !important;
    margin-left: 20px !important;
    margin-top: 15px !important;
    max-width: 440px;
    
}

.card-items-container-authorizations {
    max-height: calc(100vh - 340px) !important;
    margin-left: 8px;
}

.card-items-container-detail{
    max-height: calc(100vh - 230px);
    display: block !important;
    margin-left: 20px !important;
    margin-top: 15px !important;
    max-width: 440px;
}

.card-items-container-authorizations-detail{
    max-height: calc(100vh - 280px) !important;
    padding-left: 40px !important;
    padding-right: 25px !important;
}
.group-icon{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.group-icon:hover {
    background-color: var(--light) !important;
}
.rotatesvg  {
    rotate: 270deg;
}
