.main-container-collapsed{
    position:fixed;
        left:52px;
        top: 48px;
        transition: left 0.2s, width 0.2s;
        width:calc(100% - 52px);
}
.main-container-extended{
    position:fixed;
    left:206px;
    top: 48px;
    transition: left 0.2s, width 0.2s;
    width:calc(100% - 206px);
}
