.label-omni {
    font-weight: 600;
    font-size: 14px;
}

.container-omni-result {
    padding-left: 12px;
    padding-top: 5px;
}

.container-controls {
    padding-top: 9px;
    padding-bottom: 9px;
}
/* Style  for OmniInfoLabel*/
.omniinfolabel-container {
    display: flex;
    align-items: center;
}
.omniinfolabel-text {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 5px;
    color: var(--dark) !important;
}

.omniinfolabel-icon{
    margin-left:5px;
}
    .omniinfolabel-icon img {
        vertical-align:top;
        width:10px;
        height:10px;
    }
.colorLabel {
    display: flex;
    align-items: center;
}
.label-success {
    color: var(--success) !important;
}
.colorLabelContentBlue {
    color: var(--primary) !important;
    font-weight: 600;
}

.required {
    color: #d13438;
    padding-left:4px;
}