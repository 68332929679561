.datepicker-omni {
    width: 73%;
    background-color: var(--light-hover) !important;
}
    .datepicker-omni >span :focus {
        border-bottom: 2px solid var(--info) !important;
    }
.fui-CalendarDayGrid__dayIsToday > span {
    background-color: transparent !important;
    color: var(--dark) !important;
}
.fui-CalendarDayGrid__hoverStyle > button {
    background-color: rgb(237, 235, 233) !important;
    border-color: rgb(237, 235, 233) !important;
}
.fui-CalendarDayGrid__hoverStyle {
    background-color: rgb(237, 235, 233) !important;
    border-color: rgb(237, 235, 233) !important;
}
.fui-CalendarDayGrid__daySingleSelected > button {
    border-color: var(--dark) !important;
    background-color: rgb(237, 235, 233) !important;
    color: var(--dark) !important;
}
button[aria-disabled="false"]:hover {
    background-color: rgb(237, 235, 233) !important;
}
.fui-CalendarDay__monthAndYear:hover {
    background-color: rgb(237, 235, 233) !important;
    color: var(--dark) !important;
}
/*.datepicker-omni > div > div > div>div {
    background-color: var(--light-hover) !important;
}
.datepicker-omni > div > div > div ::after {
    background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
    border-bottom: 2px solid var(--info) var(--primary) !important;

}
.datepicker-omni > div > div > div {
    border-bottom: 1px solid rgb(96, 94, 92) !important;
}
*/