.item-container{
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    /*border: 1px solid var(--light);*/
    border-bottom: 1px solid var(--light);
}
.item-container:hover{
    cursor: pointer;
    background-color: var(--primary);
    color: #fff;
}

.item-container:hover>.item-description>span>.item-text-find{
    color: #fff;
    font-weight: 700;
}


.item-container-selected{
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: var(--primary);
    color: #fff;
    cursor: pointer;
}

.item-acronym {
    color: #fff;
    background-color: var(--primary);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding-top: 4px;
    text-transform: uppercase;
    aspect-ratio: 1;
}

.item-container:hover>.item-acronym{
    background-color: #fff;
    color: var(--primary);
}

.item-acronym-selected {
    background-color: #fff;
    color: var(--primary);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding-top: 4px;
    text-transform: uppercase;
    aspect-ratio: 1;
}

.item-description{
    font-size: 14px;
    padding-left: 10px;
    padding-top: 4px;
}


.item-container-selected>.item-description>span>.item-text-find{
    color: #fff;
    font-weight: 700;
}

.item-text-find{
    color:var(--primary);
}