/* Add */
.switch-omni {
    margin-bottom: 8px;
    margin-top: 8px;
}
.rw4brat:enabled:checked ~ .fui-Switch__indicator { /* VERIFICAR */
    background-color: var(--primary) !important;
}

.rw4brat:enabled:not(:checked) ~ .fui-Switch__indicator {
    color: var(--secondary) !important;
}
.rw4brat:enabled:not(:checked):hover ~ .fui-Switch__indicator {
    color: var(--secondary) !important;
} 





/* Details */
.switch-omni-disabled > label {
    color: var(--dark) !important;
    font-size: 15px !important;
}

.switch-omni-disabled > div > svg {
    background-color: var(--secondary);    
    border-radius: var(--borderRadiusCircular);
    color: white;
}

.switch-omni-disabled > div {
    background-color: var(--secondary) !important;
}


/* info switch */
.omniinfolabel-container-top-switch {
    display: inline-flex;
}
.omni-switch-end{
    justify-content: flex-end;
}
    .omniinfolabel-container-top-switch > span {
        font-weight: 600;
        font-size: 14px;
        color: var(--dark) !important;
    }
.omniinfolabel-icon-switch {
    position: absolute;
}

    .omniinfolabel-icon-switch img {
        vertical-align: top;
        width: 10px;
        height: 10px;
    }