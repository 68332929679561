.show {
    display: block;
    transition: display 0.2s;
}

.hide {
    display: none;
    transition: display 0.2s;
}




.container-menu {
    width: 206px;
    height: calc(100vh - 48px);
    padding: 0px;
    background-color: var(--light-hover);
}


.container-menu-collapse {
    width: 52px;
    height: 48px;
    user-select: none;
}
.container-menu-options {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 180px);
    scrollbar-width: none;
}
.container-menu-cuvitek {
    height: 36px;
    background-Color: var(--light-hover);
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 12px;
}

.smallMenu {
    width: 52px;
    transition: width 0.2s;
}




.icon-menu-collapse {
    position: relative;
    left: 16px;
    top: 19px;
}




.container-menu-options::-webkit-scrollbar {
    width: 4px;
}

.container-menu-options::-webkit-scrollbar-thumb {
    background: var(--light);
    border-radius: 2px;
}

.container-menu-options::-webkit-scrollbar-track:active {
    background: var(--light-hover);
    border-radius: 2px;
}


.menu-popover{
    left:34px !important;
    top:-44px !important;
}
.menu-item{
    padding: 0px !important;
}
.menu-item>span{
    width: 0px !important;
}


.position-callout-signout-small {
    left: 65px !important;
}

.position-callout-signout {
    left: 223.071px !important;
}