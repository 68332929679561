.catalog {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.catalog-border {
    border-bottom: 1px #e0e0e0 solid;
    height: 44px;
    padding-bottom: 0px;
    padding-top: 0px
}

    .catalog-border > div {
        /*padding-right: 10px;*/
    }

    .catalog-font {
        font-weight: 600 !important;
    }
    
    .padding-firstlevel {
        padding-left: 30px;
    }

    .padding-secondlevel {
        padding-left: 60px;
    }
    
    .selected {
        background-color: #F2F2F2 !important;
    }

.tree-container{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 45px);
}

.tree-item:hover{
    cursor: pointer;
    background-color: #F2F2F2;
}

.tree-section{
    padding-right: 0px !important;
}
.map-section{
    padding-left: 0px !important;
}