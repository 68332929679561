.select-omni {
    background-color: var(--light-hover) !important;
    width: 340px;
}

    .select-omni:after {
        background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
    }

@media (max-width:992px) {
    .select-omni{
        width:auto;
    }
}