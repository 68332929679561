.omni-search {
    min-width: initial !important;
    padding-right: 0px !important;
    width: 140px;
}

    .omni-search input {
        width: 140px;
    }

        .omni-search input[type="search"] {
            padding: .2em .4em;
        }

            .omni-search input[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                width: 10px;
                height: 10px;
                margin-left: 10px;
                background: linear-gradient( 45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%, #605e5c 55%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%), linear-gradient( 135deg, transparent 0%, transparent 43%, #605e5c 55%, transparent 0%, transparent 0% );
            }

.fui-Listbox {
    max-height: calc(100vh - 140px) !important;
    min-width: initial !important;
}

    .fui-Listbox::-webkit-scrollbar {
        width: 4px;
    }

    .fui-Listbox::-webkit-scrollbar-thumb {
        background: var(--light);
        border-radius: 2px;
    }

    .fui-Listbox::-webkit-scrollbar-track:active {
        background: var(--light-hover);
        border-radius: 2px;
    }

.option-omni-combobox {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: solid transparent 2px !important;
}

    .option-omni-combobox:active {
        border-left: solid var(--info) 2px !important;
        background-color: var(--light-hover) !important;
    }

        .option-omni-combobox:active > span {
            color: var( --dark) !important;
        }


    .option-omni-combobox:hover {
        border-left: solid var(--info) 2px !important;
        background-color: var(--light-hover) !important;
        color: var( --dark) !important;
    }

        .option-omni-combobox:hover > span {
            color: var( --dark) !important;
        }

    .option-omni-combobox:focus {
        border-left: solid var(--info) 2px !important;
        background-color: var(--light-hover) !important;
        outline: none !important;
    }

        .option-omni-combobox:focus > span {
            color: var( --dark) !important;
        }


    .option-omni-combobox::after {
        left: -1px !important;
        right: 0px !important;
        border-left: solid var(--info) 2px !important;
        border-bottom: transparent !important;
        border-right: transparent !important;
        border-top: transparent !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        background-color: var(--light-hover) !important;
    }

.nameOption {
    z-index: 100;
}

.option-omni-combobox > span {
    color: var( --dark);
}

.option-omni-combobox[aria-selected="true"] {
    background-color: var(--primary);
}

    .option-omni-combobox[aria-selected="true"] > span {
        color: white;
    }

    .option-omni-combobox[aria-selected="true"]::after span {
        color: var(--dark) !important;
    }
.fei6g0k >span  {
    color: black !important;
}