.check-omni {
    margin-bottom: 6px;
    margin-top: 6px;
}

    .check-omni > label {
        color: var(--dark) !important;
        font-size: 14px !important;
        padding-top: 3px !important;
        padding-bottom: 0px;
    }

.check-omni > div > svg {
    background-color: var(--primary);
}

.check-omni > div {
    border-color: var(--primary) !important;
}

/* Details */
.check-omni-disabled > label {
    color: var(--secondary) !important;
    font-size: 15px !important;
}

.check-omni-disabled > div > svg {
    background-color: var(--secondary);
    color: white;
}

.check-omni-disabled > div {
    border-color: transparent !important;
}

/* No check disabled */
.check-omni-disabled-noChecked > label {
    color: var(--secondary) !important;
    font-size: 15px !important;
}
.check-omni-disabled-noChecked > div {
    border-color: var(--secondary) !important;
}

.omniinfolabel-container-top > span {
    font-weight: 600;
    font-size: 14px;
    color: var(--dark) !important;
    flex-direction: column-reverse;
    display: inline-flex;
}
.omniinfolabel-container-top {
    display: flex;
}
