.radio-group-omni > div > span > label {
    color: var(--dark) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: normal !important;
}

.radio-group-omni > div > span > div {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}

.radio-omni > span > div {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}